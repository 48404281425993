import { closeModal } from '../common/modals';
import Cookies from 'js-cookie';

(() => {
    const $siteFooterBottomSpacer = document.querySelector('.site-footer-bottom-spacer');
    const $cookiesNotice = document.querySelector('.cookies-notice');
    const $cookiesModal = document.querySelector('.cookies-notice-options');
    const $acceptAllBtn = document.querySelector('.cookies-notice__accept-all-btn');
    const $cookiesForm = document.querySelector('.cookies-notice__form');

    if (!$cookiesNotice) return;

    const siteFooterCookiesNoticePadding = (padding = null) => {
        if (!$siteFooterBottomSpacer) return;

        $siteFooterBottomSpacer.style.paddingBottom = `${padding ?? $cookiesNotice.offsetHeight}px`;
    };

    const initAnalyticalCode = cookieConsent => {
        let cookieConsentAccepted = cookieConsent ? cookieConsent : (Cookies.get('cookieConsent') ? JSON.parse(Cookies.get('cookieConsent'))?.analytical : false);

        if (!cookieConsentAccepted) return;
        
        if (GLOBALS?.GOOGLE_UA_ID) {
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());

            gtag('config', GLOBALS.GOOGLE_UA_ID);
        }

        if (GLOBALS?.GOOGLE_GA4_ID) {
            (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
            (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
            m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
            })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');

            ga('create', GLOBALS.GOOGLE_GA4_ID, 'auto');
            ga('require', 'linkid');
            ga('send', 'pageview');
        }

        if (GLOBALS?.LEAD_FORENSICS_ID) {
            const leadForensicsScript = document.createElement('script');
            leadForensicsScript.type = 'text/javascript';
            leadForensicsScript.src = `https://secure.lane5down.com/js/sc/${GLOBALS.LEAD_FORENSICS_ID}.js`;
            
            document.head.appendChild(leadForensicsScript);
        }

        if (GLOBALS?.HOTJAR_ID) {
            (function(h,o,t,j,a,r){
                h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                h._hjSettings={hjid:GLOBALS?.HOTJAR_ID,hjsv:6};
                a=o.getElementsByTagName('head')[0];
                r=o.createElement('script');r.async=1;
                r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                a.appendChild(r);
            })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
        }
    }

    const acceptCookies = (allowNecessaryCookies, allowAnalyticalCookies, allowMarketingCookies) => {
        if (!allowNecessaryCookies) {
            Cookies.remove('cookieConsent');
            return;
        }

        Cookies.set('cookieConsent', JSON.stringify({ 
            necessary: allowNecessaryCookies ? true : false,
            analytical: allowAnalyticalCookies ? true : false,
            marketing: allowMarketingCookies ? true : false
        }), { expires: 365 });

        $cookiesNotice.classList.add('hide');

        initAnalyticalCode(allowAnalyticalCookies ? true : false);

        setTimeout(() => {
            $cookiesNotice.parentNode.removeChild($cookiesNotice);
        }, 500);
    };

    const displayCookieConsentNotice = () => {
        const cookieConsent = Cookies.get('cookieConsent') ? JSON.parse(Cookies.get('cookieConsent')) : null;

        if (!cookieConsent || !cookieConsent.necessary) {
            $cookiesNotice.classList.remove('hide');
        }

        siteFooterCookiesNoticePadding();
    };

    if ($acceptAllBtn) {
        $acceptAllBtn.addEventListener('click', () => {
            acceptCookies(true, true, true);

            siteFooterCookiesNoticePadding(0);
        });
    }

    if ($cookiesForm) {
        $cookiesForm.addEventListener('submit', e => {
            e.preventDefault();

            const necessaryCookies = e.target.querySelector('input[name="necessary-cookies"]');
            const analyticalCookies = e.target.querySelector('input[name="analytical-cookies"]');
            const marketingCookies = e.target.querySelector('input[name="marketing-cookies"]');

            acceptCookies(necessaryCookies?.checked, analyticalCookies?.checked, marketingCookies?.checked);

            if ($cookiesModal) {
                closeModal($cookiesModal);
            }

            siteFooterCookiesNoticePadding(0);
        });
    }

    window.addEventListener('resize', () => {
        siteFooterCookiesNoticePadding();
    });

    siteFooterCookiesNoticePadding();
    displayCookieConsentNotice();
    initAnalyticalCode();
})();
