import { isValidUrl } from '../common/helpers';
import { closeModal } from '../common/modals';

(() => {
    const $siteMenuModal = document.querySelector('.site-menu-modal');
    const $siteMenuModalLinks = document.querySelectorAll('.site-menu-modal a');
    const $siteMenuItems = document.querySelectorAll('.site-header .site-menu-list .menu-item');

    const initSiteMenuModalLinks = () => {
        if (!$siteMenuModal || !$siteMenuModalLinks) return;

        $siteMenuModalLinks.forEach($siteMenuModalLink => {
            $siteMenuModalLink.addEventListener('click', () => {
                closeModal($siteMenuModal);
            });
        });
    };

    const setActiveSiteMenuLink = () => {
        if (!$siteMenuItems) return;

        const $activeSiteMenuItems = document.querySelectorAll('.site-header .site-menu-list .menu-item--active');

        let $activeSiteMenuItem = null;

        $siteMenuItems.forEach($siteMenuItem => {
            const $siteMenuLink = $siteMenuItem.querySelector('a');

            if (!$siteMenuLink || !$siteMenuLink?.href || !isValidUrl($siteMenuLink.href)) return;
            
            const anchorURL = new URL($siteMenuLink.href);
    
            if (!anchorURL?.hash) return;
    
            const $anchorEl = document.querySelector(anchorURL?.hash);
    
            if (!$anchorEl) return;

            if (window.scrollY + window.innerHeight < document.body.clientHeight && $anchorEl.getBoundingClientRect().top - 60 >= 0) return;

            $activeSiteMenuItem = $siteMenuItem;
        });

        if ($activeSiteMenuItems) {
            $activeSiteMenuItems.forEach($activeSiteMenuItem => {
                $activeSiteMenuItem.classList.remove('menu-item--active');
            });
        }

        if ($activeSiteMenuItem) {
            $activeSiteMenuItem.classList.add('menu-item--active');
        }
    };

    window.addEventListener('resize', setActiveSiteMenuLink);
    window.addEventListener('scroll', setActiveSiteMenuLink);

    initSiteMenuModalLinks();
    setActiveSiteMenuLink();
})();
