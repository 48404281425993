(() => {
    const initSiteNavDropdowns = () => {
        const $mobileSubMenuWraps = document.querySelectorAll('.mobile-menu .menu-item-has-children');
        const $desktopSubMenuWraps = document.querySelectorAll('.desktop-menu .menu-item-has-children');

        if ($mobileSubMenuWraps.length) {
            $mobileSubMenuWraps.forEach($subMenuWrap => {
                const $subMenuWrapBtn = $subMenuWrap.querySelector('a');
                const $subMenu = $subMenuWrap.querySelector('.sub-menu');

                const showSubMenu = () => {
                    $subMenu.style.height = `${$subMenu.scrollHeight}px`;
                    $subMenuWrap.classList.add('active');

                    setTimeout(() => {
                        $subMenu.style.height = '';
                    }, 250);
                };

                const hideSubMenu = () => {
                    $subMenu.style.height = `${$subMenu.scrollHeight}px`;
                    $subMenuWrap.classList.remove('active');

                    setTimeout(() => {
                        $subMenu.style.height = '';
                    }, 10);
                };

                $subMenuWrapBtn.addEventListener('click', e => {
                    e.preventDefault();

                    $subMenuWrapBtn.removeEventListener('mouseleave', () => {
                        hideSubMenu();
                    });

                    if ($subMenuWrap.classList.contains('active')) {
                        hideSubMenu();
                    } else {
                        showSubMenu();
                    }
                });
            });
        }

        if ($desktopSubMenuWraps.length) {
            $desktopSubMenuWraps.forEach($subMenuWrap => {
                const $subMenuWrapBtn = $subMenuWrap.querySelector('a');

                $subMenuWrap.addEventListener('mouseenter', () => {
                    $subMenuWrap.classList.add('active');
                });

                $subMenuWrap.addEventListener('mouseleave', () => {
                    $subMenuWrap.classList.remove('active');
                });

                $subMenuWrapBtn.addEventListener('click', e => {
                    e.preventDefault();
                });
            });
        }
    };

    initSiteNavDropdowns();
})();
